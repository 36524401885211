<template>
  <div>
    <h1><slot name="titre">{{ _title }}</slot></h1>
    <div v-if="!editedTicket">
      Vous souhaitez signaler un bug, suggérer une amélioration <web-area>du site</web-area><mobile-app-area>de l'application</mobile-app-area> ou, tout simplement, vous avez une idée à partager avec les concepteurs ou réalisateurs <web-area>du site</web-area><mobile-app-area>de l'application</mobile-app-area> ?<br/>
      Rien de plus simple, il vous suffit de remplir le formulaire ci-dessous en prenant bien soin d'être le plus précis possible lorsque vous renseignez une information.
    </div>
    <div v-else>

    </div>
    <form-field
      label="Type de ticket"
    >
      <b-radio-group v-model="buffer.type">
      </b-radio-group>
    </form-field>
    <secured-area>

    </secured-area>
  </div>
</template>

<script>
import MobileAppArea from '../MobileAppArea.vue';
import WebArea from '../WebArea.vue';
export default {
  components: { WebArea, MobileAppArea },
  model: { prop: 'editedTicket'},
  props: {
    editedTicket: { type:Object, required:false },
    title: { type:String, required:false},
  },
  data(){
    return {
      buffer: {},
    }
  },
  computed:{
    _title(){
      if(this.title) return this.title;
      if(!this.editedTicket) return 'Ouverture de ticket';
      return "Suivi de ticket";
    }
  },
  methods:{
    initBuffer(value){
      this.buffer = (value != null)
                  ? JSON.parse(JSON.stringify(value))
                  : { type: '', description: '', isClosed:false} ;
    },
  }

}
</script>
